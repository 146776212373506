@import "./svgsprite.css";

* {
  /* background: rgba(0, 255, 0, .01)!important; */
}

@font-face {
  font-family: "Manrope";
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: local("Manrope"),
  local("Manrope-Regular"),
  url("./assets/fonts/Manrope-Regular.woff2") format("woff2"),
  url("./assets/fonts/Manrope-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Manrope";
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: local("Manrope"),
  local("Manrope-Bold"),
  url("./assets/fonts/Manrope-Bold.woff2") format("woff2"),
  url("./assets/fonts/Manrope-Bold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Bold.ttf');
  font-weight: bold; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Heavy.ttf');
  font-weight: 900; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-HeavyItalic.ttf');
  font-weight: 900; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Light.ttf');
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-LightItalic.ttf');
  font-weight: 200; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Medium.ttf');
  font-weight: 500; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-MediumItalic.ttf');
  font-weight: 500; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Regular.ttf');
  font-weight: 400; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-RegularItalic.ttf');
  font-weight: 400; 
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-Semibold.ttf');
  font-weight: 600; 
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFPROTEXT/SFProText-SemiboldItalic.ttf');
  font-weight: 600; 
  font-style: italic;
}


@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Heavy.eot');
  src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.eot');
  src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-UltralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.eot');
  src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Thin.eot');
  src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Light.eot');
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.eot');
  src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Black.eot');
  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.eot');
  src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.eot');
  src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.eot');
  src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.eot');
  src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.eot');
  src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.eot');
  src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.eot');
  src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.woff') format('woff'),
      url('./assets/fonts/SFPRO/SFProDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Days";
  src: url("./assets/fonts/days-webfont.eot");
  src: url("./assets/fonts/days-webfont.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/days-webfont.woff") format("woff"),
    url("./assets/fonts/days-webfont.ttf") format("truetype"),
    url("./assets/fonts/days-webfont.svg#daysregular") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-Bold.eot");
  src: local("SF UI Text Bold"), local("SFUIText-Bold"),
    url("./assets/fonts/SFUIText-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-Bold.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-Bold.woff") format("woff"),
    url("./assets/fonts/SFUIText-Bold.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-Bold.svg#SFUIText-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-Regular.eot");
  src: local("SF UI Text Regular"), local("SFUIText-Regular"),
    url("./assets/fonts/SFUIText-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-Regular.woff") format("woff"),
    url("./assets/fonts/SFUIText-Regular.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-Regular.svg#SFUIText-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-Semibold.eot");
  src: local("SF UI Text Semibold"), local("SFUIText-Semibold"),
    url("./assets/fonts/SFUIText-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-Semibold.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-Semibold.woff") format("woff"),
    url("./assets/fonts/SFUIText-Semibold.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-Semibold.svg#SFUIText-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-RegularItalic.eot");
  src: local("SF UI Text Italic"), local("SFUIText-RegularItalic"),
    url("./assets/fonts/SFUIText-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-RegularItalic.woff") format("woff"),
    url("./assets/fonts/SFUIText-RegularItalic.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-RegularItalic.svg#SFUIText-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-SemiboldItalic.eot");
  src: local("SF UI Text Semibold Italic"), local("SFUIText-SemiboldItalic"),
    url("./assets/fonts/SFUIText-SemiboldItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-SemiboldItalic.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-SemiboldItalic.woff") format("woff"),
    url("./assets/fonts/SFUIText-SemiboldItalic.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-SemiboldItalic.svg#SFUIText-SemiboldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-BoldItalic.eot");
  src: local("SF UI Text Bold Italic"), local("SFUIText-BoldItalic"),
    url("./assets/fonts/SFUIText-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-BoldItalic.woff") format("woff"),
    url("./assets/fonts/SFUIText-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-BoldItalic.svg#SFUIText-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-MediumItalic.eot");
  src: local("SF UI Text Medium Italic"), local("SFUIText-MediumItalic"),
    url("./assets/fonts/SFUIText-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-MediumItalic.woff") format("woff"),
    url("./assets/fonts/SFUIText-MediumItalic.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-MediumItalic.svg#SFUIText-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("./assets/fonts/SFUIText-Medium.eot");
  src: local("SF UI Text Medium"), local("SFUIText-Medium"),
    url("./assets/fonts/SFUIText-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFUIText-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFUIText-Medium.woff") format("woff"),
    url("./assets/fonts/SFUIText-Medium.ttf") format("truetype"),
    url("./assets/fonts/SFUIText-Medium.svg#SFUIText-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

.font-family {
  font-family: "SF UI Text", sans-serif;
  letter-spacing: 0;
}

.font-family-big {
  font-family: "SF UI Text", sans-serif;
  letter-spacing: 0.02em;
}

@font-face {
  font-family: "SF UI Display";
  src: url("./assets/fonts/sf_ui_display_regular.eot");
  src: local("SF UI Display Regular"), local("sf_ui_display_regular"),
    url("./assets/fonts/sf_ui_display_regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sf_ui_display_regular.woff2") format("woff2"),
    url("./assets/fonts/sf_ui_display_regular.woff") format("woff"),
    url("./assets/fonts/sf_ui_display_regular.ttf") format("truetype"),
    url("./assets/fonts/sf_ui_display_regular.svg#sf_ui_display_regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("./assets/fonts/sf_ui_display_medium.eot");
  src: local("SF UI Display Medium"), local("SFUIDisplay-Medium"),
    url("./assets/fonts/sf_ui_display_medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sf_ui_display_medium.woff2") format("woff2"),
    url("./assets/fonts/sf_ui_display_medium.woff") format("woff"),
    url("./assets/fonts/sf_ui_display_medium.ttf") format("truetype"),
    url("./assets/fonts/sf_ui_display_medium.svg#SFUIText-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("./assets/fonts/sf_ui_display_semibold.eot");
  src: local("SF UI Text Semibold"), local("sf_ui_display_semibold"),
    url("./assets/fonts/sf_ui_display_semibold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sf_ui_display_semibold.woff2") format("woff2"),
    url("./assets/fonts/sf_ui_display_semibold.woff") format("woff"),
    url("./assets/fonts/sf_ui_display_semibold.ttf") format("truetype"),
    url("./assets/fonts/sf_ui_display_semibold.svg#sf_ui_display_semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Dislay";
  src: url("./assets/fonts/sf_ui_display_bold.eot");
  src: local("SF UI Display Bold"), local("sf_ui_display_bold"),
    url("./assets/fonts/sf_ui_display_bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/sf_ui_display_bold.woff2") format("woff2"),
    url("./assets/fonts/sf_ui_display_bold.woff") format("woff"),
    url("./assets/fonts/sf_ui_display_bold.ttf") format("truetype"),
    url("./assets/fonts/sf_ui_display_bold.svg#sf_ui_display_bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

/*CSS Reset and PreStyles*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
header,
nav,
section,
aside,
article,
time,
footer {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
}

form,
header,
nav,
section,
aside,
article,
time,
footer {
  display: block;
  width: 100%;
}

a img {
  border: none;
}

ul,
ol {
  display: block;
  margin: 0 0 20px 0;
  padding: 0 0 0 20px;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
  position: relative;
}

ul {
  padding-left: 20px;
  list-style: disc outside;
}

ol {
  counter-reset: item;
}

ol > li:before {
  display: block;
  float: left;
  margin-left: -20px;
  counter-increment: item;
  content: counters(item, ".") ".";
}

html,
body {
  color: #000000;
  padding: 0;
  margin: 0;
  font-family: "SF UI Text", "Arial", "Helvetica", sans-serif;
  font-size: 13px;
  line-height: 16px;
  height: 100%;
  min-width: 320px;
  overflow-x: hidden;
  /* padding-right: calc(20px - (100vw - 100%)); решил проблему прыгающей страницы при появлении скрола  */
}

#root {
  display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.page {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  .page {
    flex: 1 0 auto;
  }
}

.mainwrap {
  flex: 1 1 auto;
  display: flex;
}

.wrap {
  max-width: 940px;
  width: 100%;
  margin: auto;
}

.clear {
  clear: both;
  overflow: hidden;
}
