@import "../../MUI/Theme";

.Footer {
  display: flex;
  min-height: 80px;
  padding: 0 18px;
  box-sizing: border-box;
  &Wrap {
    max-width: 1118px;
    width: 100%;
    margin: auto;
  }

  &Text {
    font-family: "SF UI Text";
    justify-content: flex-end;

    @include mediaTo(sm) {
      flex-direction: column-reverse;
    }

    & a {
      // display: block;
      text-align: right;
      font-size: 13px;
      line-height: 19px;
    }
    & div {
      text-align: left;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;

      @include mediaTo(sm) {
        text-align: right;
      }
    }
  }

  &Links {
    display: flex;
    font-family: "SF UI Text";
    text-align: center;
    flex-direction: column;
    @include mediaTo(sm) {
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      text-align: right;
      padding: 0;
      // min-height: 46px;
    }
  }
}
