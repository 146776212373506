.card {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 0;
  box-sizing: border-box;

  &:global(.block) {
    padding: 18px 20px;
  }

  & + & {
    margin-top: 10px;
  }

  :global(.block:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
