.svgIcon {
  width: 16px;
  height: 0px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}


.opacity {
  opacity: 1;

  :hover > &,
  &Inversed {
    opacity: 0.7;
  }

  :hover > &Inversed {
    opacity: 1;
  }
}
