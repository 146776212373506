.svgicon-star {
    vertical-align: middle
}

.svgicon {
    display: block;
    width: 20px;
    height: 20px;
    background: url('./svgsprite.svg')
}

.svgicon-chats-curators {
    width: 36px;
    height: 36px;
    margin: 0 auto 5px;
    background-position: 0 -1340px
}

.active > .svgicon-chats-curators {
    background-position: -40px -1340px
}

.svgicon-chats-students {
    width: 36px;
    height: 36px;
    margin: 0 auto 5px;
    background-position: -80px -1340px
}

.active > .svgicon-chats-students {
    background-position: -120px -1340px
}

.svgicon-chats-chats {
    width: 36px;
    height: 36px;
    margin: 0 auto 5px;
    background-position: -160px -1340px
}

.svgicon-userpic {
    width: 44px;
    height: 44px;
    background-position: 0 -1440px;
}

.svgicon-userpic.small {
    width: 28px;
    height: 28px;
    background-position: 0 -1500px;
}

.active > .svgicon-chats-chats {
    background-position: -200px -1340px
}

.svgicon-task-text {
    background-position: 0 -60px
}

.svgicon-task-fill {
    background-position: -20px -60px
}

.svgicon-task-file {
    background-position: -40px -60px
}

.svgicon-task-record {
    background-position: -60px -60px
}

.svgicon-task-statistic {
    background-position: -80px -60px
}

.svgicon-task-radio {
    background-position: -200px -60px
}

.svgicon-task-radio_img {
    background-position: -220px -60px
}

.svgicon-task-checkbox {
    background-position: -240px -60px
}

.svgicon-task-checkbox_img {
    background-position: -260px -60px
}

.svgicon-task-sort {
    background-position: -280px -60px
}

.svgicon-task-sort_img {
    background-position: -300px -60px
}

.svgicon-task-relation {
    background-position: -320px -60px
}

.svgicon-task-equally {
    background-position: -340px -60px
}

.svgicon-task-image_text_relation {
    background-position: -360px -60px
}

.svgicon-radio-check {
    width: 32px;
    height: 32px;
    background-position: -420px -2050px
}

.svgicon-radio-checked {
    width: 32px;
    height: 32px;
    background-position: -451px -2050px
}

.svgicon-points-menu {
    background-position: -490px -2060px;
}

.svgicon-gs-shop {
    width: 30px;
    height: 30px;
    background-position: 0 -2270px
}

.svgicon-gs-subscribe {
    width: 30px;
    height: 30px;
    background-position: -40px -2270px
}

.svgicon-gs-subscribe-plus {
    width: 30px;
    height: 30px;
    background-position: -80px -2270px
}

.svgicon-gs-shop-plus {
    width: 30px;
    height: 30px;
    background-position: -120px -2270px
}

.svgicon-gs-subscribe-graph {
    width: 77px;
    height: 77px;
    background-position: -160px -2270px
}

.svgicon-gs-shop-graph {
    width: 77px;
    height: 77px;
    background-position: -250px -2270px
}

.svgicon-gs-subscribe-active {
    width: 30px;
    height: 30px;
    background-position: -330px -2270px
}

@media (max-width: 767px) {
    .svgicon-gs-subscribe-active {
        width: 48px;
        height: 40px;
        background-position: -394px -2238px
    }
}

.svgicon-gs-subscribe-pause {
    width: 30px;
    height: 30px;
    background-position: -330px -2238px
}

@media (max-width: 767px) {
    .svgicon-gs-subscribe-pause {
        width: 48px;
        height: 40px;
        background-position: -444px -2238px
    }
}

.svgicon-gs-subscribe-blocked {
    width: 30px;
    height: 30px;
    background-position: -362px -2238px
}

@media (max-width: 767px) {
    .svgicon-gs-subscribe-blocked {
        width: 48px;
        height: 40px;
        background-position: -494px -2238px
    }
}

.svgicon-gs-hours-big {
    width: 40px;
    height: 40px;
    background-position: -545px -2238px
}

@media (max-width: 767px) {
    .svgicon-gs-hours-big {
        width: 26px;
        height: 26px;
        background-position: -370px -2320px
    }
}

.svgicon-gs-lessons-all-big {
    width: 40px;
    height: 40px;
    background-position: -585px -2238px
}

@media (max-width: 767px) {
    .svgicon-gs-lessons-all-big {
        width: 20px;
        height: 20px;
        background-position: -400px -2290px
    }
}

.svgicon-gs-lessons-tasks-big {
    width: 40px;
    height: 40px;
    background-position: -625px -2238px
}

@media (max-width: 767px) {
    .svgicon-gs-lessons-tasks-big {
        width: 20px;
        height: 20px;
        background-position: -370px -2290px
    }
}

.svgicon-gs-lessons-tests-big {
    width: 40px;
    height: 40px;
    background-position: -665px -2238px
}

@media (max-width: 767px) {
    .svgicon-gs-lessons-tests-big {
        width: 20px;
        height: 20px;
        background-position: -400px -2320px
    }
}

.svgicon-courses-expand {
    width: 12px;
    height: 7px;
    background-position: -245px -2050px
}

.svgicon-courses-expand.active {
    background-position: -245px -2060px
}

.svgicon-courses-trolley {
    width: 36px;
    height: 36px;
    background-position: -330px -2320px
}

.svgicon-courses-clock {
    width: 26px;
    height: 26px;
    background-position: -370px -2320px
}

.svgicon-courses-clock.disabled {
    background-position: -300px -2238px
}

.svgicon-courses-test-dark {
    width: 20px;
    height: 20px;
    background-position: -370px -2290px
}

.svgicon-courses-test-dark.disabled {
    background-position: -370px -2350px
}

@media (max-width: 767px) {
    .svgicon-courses-test-dark {
        width: 26px;
        height: 26px;
        background-position: -420px -2290px
    }
}

.svgicon-courses-lesson-dark {
    width: 20px;
    height: 20px;
    background-position: -400px -2290px
}

.svgicon-courses-lesson-dark.disabled {
    background-position: -400px -2340px
}

@media (max-width: 767px) {
    .svgicon-courses-lesson-dark:not(.original) {
        width: 26px;
        height: 26px;
        background-position: -505px -1670px
    }
}

.svgicon-courses-lessons-task-dark {
    width: 20px;
    height: 20px;
    background-position: -400px -2320px
}

.svgicon-courses-lessons-task-dark.disabled {
    background-position: -400px -2360px
}

@media (max-width: 767px) {
    .svgicon-courses-lessons-task-dark {
        width: 26px;
        height: 26px;
        background-position: -420px -2320px
    }
}

.svgicon-courses-smalllock {
    width: 26px;
    height: 26px;
    background-position: -690px -1610px
}

.svgicon-courses-smalllock.allowed {
    background-position: -690px -1640px
}

.svgicon-courses-lock-closed {
    width: 50px;
    height: 76px;
    background-position: -450px -2290px
}

.svgicon-courses-lock-handle {
    width: 32px;
    height: 36px;
    background-position: -510px -2320px
}

.svgicon-courses-lock-body {
    width: 50px;
    height: 76px;
    background-position: -550px -2320px
}

.svgicon-courses-convertgroup {
    width: 36px;
    height: 32px;
    background-position: 0 -2350px
}

@media (max-width: 767px) {
    .svgicon-courses-convertgroup {
        width: 40px;
        height: 30px;
        background-position: -40px -2350px
    }
}

.svgicon-sl-delete {
    width: 20px;
    height: 20px;
    background-position: 0 -2100px;
    opacity: .5
}

.svgicon-sl-delete.active, :hover > .svgicon-sl-delete {
    opacity: 1
}

.svgicon-sl-subscribe-extend {
    width: 20px;
    height: 20px;
    background-position: -25px -2100px;
    opacity: .5
}

.svgicon-sl-subscribe-extend.active, :hover > .svgicon-sl-subscribe-extend {
    opacity: 1
}

.svgicon-sl-paused {
    width: 20px;
    height: 20px;
    background-position: -50px -2100px;
    opacity: .5
}

.svgicon-sl-paused.active, :hover > .svgicon-sl-paused {
    opacity: 1
}

.svgicon-sl-play {
    width: 20px;
    height: 20px;
    background-position: -75px -2100px;
    opacity: .5
}

.svgicon-sl-play.active, :hover > .svgicon-sl-play {
    opacity: 1
}

.svgicon-sl-restore {
    width: 22px;
    height: 22px;
    background-position: -25px -2125px;
    opacity: .5
}

.svgicon-sl-restore.active, :hover > .svgicon-sl-restore {
    opacity: 1
}

.svgicon-sl-addstudents {
    width: 24px;
    height: 24px;
    background-position: 0 -2125px
}

.svgicon-sl-sort {
    background-position: -50px -2125px
}

.svgicon-sl-sort.desc {
    background-position: -70px -2125px
}

.svgicon-sl-unload {
    width: 20px;
    height: 20px;
    background-position: -100px -2125px
}

.svgicon-sl-deleted {
    width: 20px;
    height: 20px;
    background-position: -125px -2100px
}

.svgicon-sl-deleted.big {
    width: 30px;
    height: 30px;
    background-position: -175px -2100px
}

.svgicon-sl-blocked {
    width: 20px;
    height: 20px;
    background-position: -125px -2125px
}

.svgicon-sl-blocked.big {
    width: 30px;
    height: 30px;
    background-position: -175px -2135px
}

.svgicon-sl-active {
    width: 20px;
    height: 20px;
    background-position: -150px -2100px
}

.svgicon-sl-active.big {
    width: 30px;
    height: 30px;
    background-position: -210px -2100px
}

.svgicon-sl-pause {
    width: 20px;
    height: 20px;
    background-position: -150px -2125px
}

.svgicon-sl-pause.big {
    width: 30px;
    height: 30px;
    background-position: -210px -2135px
}

.svgicon-sl-reward {
    width: 24px;
    height: 24px;
    background-position: -245px -2100px
}

.svgicon-sl-listexample {
    background-position: -245px -2125px
}

.svgicon-rotate-cw {
    width: 30px;
    height: 30px;
    background-position: -15px -1730px
}

.svgicon-rotate-rcw {
    width: 30px;
    height: 30px;
    background-position: -45px -1730px
}

.svgicon-course {
    width: 34px;
    height: 26px;
    background-position: -125px -30px
}

.svgicon-course.small {
    width: 28px;
    height: 22px;
    background-position: -270px -30px
}

.svgicon-coursegroup {
    width: 63px;
    height: 42px;
    background-position: -335px -5px
}

.svgicon-coursegroup.trash {
    width: 34px;
    height: 39px;
    background-position: -300px -5px
}

.svgicon-coursecount {
    width: 12px;
    height: 17px;
    background-position: -285px -5px
}

.svgicon-coursecancel {
    width: 26px;
    height: 26px;
    background-position: -630px -1610px;
    opacity: .5
}

.svgicon-coursecancel.active {
    opacity: 1
}

.svgicon-courseoverdue {
    width: 26px;
    height: 26px;
    background-position: -660px -1610px;
    opacity: .5
}

.svgicon-courseoverdue-active, :hover > .svgicon-courseoverdue {
    opacity: 1
}

.svgicon-coursedone {
    width: 26px;
    height: 26px;
    background-position: -720px -1610px;
    opacity: .5
}

.svgicon-coursedone-active, :hover > .svgicon-coursedone {
    opacity: 1
}

.svgicon-coursepaused {
    width: 26px;
    height: 26px;
    background-position: 720px -1640px;
    opacity: .5
}

.svgicon-coursepaused-active, :hover > .svgicon-coursepaused {
    opacity: 1
}

.svgicon-form-add {
    width: 30px;
    height: 30px;
    background-position: -630px -1640px
}

.svgicon-form-remove {
    width: 30px;
    height: 30px;
    background-position: -660px -1640px
}

.svgicon-form-info {
    background-position: -630px -1670px
}

.svgicon-form-info.white {
    background-position: -650px -1670px
}

.svgicon-lessontype_task {
    width: 22px;
    height: 26px;
    background-position: 0 -30px
}

.svgicon-lessontype_task.small {
    width: 20px;
    height: 23px;
    background-position: -160px -30px
}

.svgicon-lessontype_test {
    width: 26px;
    height: 26px;
    background-position: -25px -30px
}

.svgicon-lessontype_test.small {
    width: 22px;
    height: 22px;
    background-position: -185px -30px
}

.svgicon-lessontype_theory {
    width: 32px;
    height: 24px;
    background-position: -55px -30px
}

.svgicon-lessontype_theory.small {
    width: 26px;
    height: 20px;
    background-position: -210px -30px
}

.svgicon-lessontype_webinar {
    width: 36px;
    height: 22px;
    background-position: -87px -30px
}

.svgicon-lessontype_webinar.small {
    width: 28px;
    height: 18px;
    background-position: -240px -30px
}

.svgicon-chat {
    width: 23px;
    height: 20px;
    background-position: -330px -1810px
}

a:hover > .svgicon-chat {
    background-position: -355px -1810px
}

.svgicon-chat-create {
    background-position: -280px -1810px
}

a:hover > .svgicon-chat-create {
    background-position: -305px -1810px
}

.svgicon-modal-close {
    background-position: -190px -2030px;
    opacity: .6
}

.link:hover .svgicon-modal-close, a:hover .svgicon-modal-close, button:hover .svgicon-modal-close {
    opacity: .8
}

.svgicon-modal-fullscreen {
    background-position: -210px -2030px;
    opacity: .6
}

.link:hover .svgicon-modal-fullscreen, a:hover .svgicon-modal-fullscreen, button:hover .svgicon-modal-fullscreen {
    opacity: .8
}

.svgicon-courses-big {
    width: 118px;
    height: 92px;
    background-position: -355px -1620px
}

.svgicon-courses-course {
    height: 21px;
    background-position: -250px -1680px
}

.svgicon-courses-students {
    background-position: -230px -1680px
}

@media (min-width: 768px) {
    .svgicon-courses-students.dark {
        background-position: -190px -1700px
    }
}

@media (max-width: 767px) {
    .svgicon-courses-students {
        width: 26px;
        height: 26px;
        background-position: -475px -1670px
    }
}

.svgicon-courses-homeworks {
    background-position: -250px -1640px
}

@media (min-width: 768px) {
    .svgicon-courses-homeworks.dark {
        background-position: -170px -1700px
    }
}

.svgicon-courses-homeworks.new {
    background-position: -250px -1660px
}

@media (max-width: 767px) {
    .svgicon-courses-homeworks {
        width: 30px;
        height: 30px;
        background-position: -535px -1670px
    }
}

.svgicon-courses-messages {
    background-position: -230px -1640px
}

@media (min-width: 768px) {
    .svgicon-courses-messages.dark {
        background-position: -150px -1700px
    }
}

.svgicon-courses-messages.new {
    background-position: -230px -1660px
}

@media (max-width: 767px) {
    .svgicon-courses-messages {
        width: 30px;
        height: 30px;
        background-position: -565px -1670px
    }
}

.svgicon-courses-settings {
    background-position: -295px -1640px
}

@media (max-width: 767px) {
    .svgicon-courses-settings {
        width: 30px;
        height: 30px;
        background-position: -525px -1610px
    }
}

.svgicon-courses-addgroup {
    background-position: -585px -1610px
}

@media (max-width: 767px) {
    .svgicon-courses-addgroup {
        width: 30px;
        height: 30px;
        background-position: -555px -1610px
    }

    .Mui-disabled .svgicon-courses-addgroup {
        opacity: .1
    }
}

.svgicon-courses-addcourse {
    background-position: -295px -1660px
}

.svgicon-courses-addcourse.white {
    background-position: -585px -1630px
}

@media (max-width: 767px) {
    .svgicon-courses-addcourse.white {
        width: 30px;
        height: 30px;
        background-position: -525px -1640px
    }

    .Mui-disabled .svgicon-courses-addcourse.white {
        opacity: .1
    }

    .svgicon-courses-insertcourse {
        width: 30px;
        height: 30px;
        background-position: -555px -1640px
    }
}

.svgicon-courses-lesson {
    background-position: -270px -1680px
}

@media (min-width: 768px) {
    .svgicon-courses-lesson.dark {
        background-position: -210px -1700px
    }
}

@media (max-width: 767px) {
    .svgicon-courses-lesson:not(.original) {
        width: 26px;
        height: 26px;
        background-position: -505px -1670px
    }
}

.svgicon-courses-profile {
    background-position: -230px -1700px
}

.svgicon-courses-profile-messages {
    background-position: -290px -1700px
}

.svgicon-courses-email {
    background-position: -250px -1700px
}

.svgicon-courses-sms {
    background-position: -270px -1700px
}

.svgicon-courses-hours {
    width: 15px;
    background-position: -690px -1670px
}

.svgicon-courses-copy {
    background-position: -315px -1640px
}

.svgicon-courses-copy.white {
    background-position: -315px -1660px
}

.svgicon-courses-delete {
    background-position: -335px -1640px
}

.svgicon-courses-delete.white {
    background-position: -335px -1660px
}

.svgicon-courses-restore {
    width: 24px;
    margin-left: -4px;
    background-position: -270px -1640px
}

.svgicon-courses-restore.white {
    background-position: -270px -1660px
}

.svgicon-courses-list {
    width: 24px;
    height: 21px;
    background-position: -475px -1620px
}

.svgicon-courses-list.active {
    background-position: -475px -1645px
}

.svgicon-courses-table {
    width: 21px;
    height: 21px;
    background-position: -500px -1620px
}

.svgicon-courses-table.active {
    background-position: -500px -1645px
}

.svgicon-courses-renew {
    width: 30px;
    height: 30px;
    background-position: -595px -1670px
}

.svgicon-courses-renew.small {
    width: 20px;
    height: 20px;
    background-position: -670px -1670px
}

.svgicon-courses-attach {
    background-position: -585px -1650px
}

.svgicon-courses-preview {
    background-position: -605px -1650px
}

.svgicon-courses-preview.disabled {
    background-position: -710px -1670px
}

.svgicon-courses-groupsubscribe {
    width: 36px;
    height: 36px;
    background-position: -40px -2310px
}

.svgicon-courses-groupsubscribe-grey {
    width: 36px;
    height: 36px;
    background-position: -116px -2310px
}

.svgicon-courses-groupshop {
    width: 36px;
    height: 36px;
    background-position: 0 -2310px
}

.svgicon-courses-groupshop-grey {
    width: 36px;
    height: 36px;
    background-position: -80px -2310px
}

.svgicon-editor {
    background-position: -605px -1610px
}

.svgicon-editor.white {
    background-position: -605px -1630px
}

.svgicon-star {
    display: inline-block;
    background-position: 0 -1640px
}

.svgicon-status {
    width: 50px;
    height: 50px;
    background-position: -30px -1640px
}

.svgicon-status.overdue {
    background-position: -80px -1640px
}

.svgicon-status.suspended {
    background-position: -130px -1640px
}

.svgicon-status.done {
    background-position: -180px -1640px
}

.svgicon-select-drop {
    width: 11px;
    height: 7px;
    background-position: 0 -1730px
}

.svgicon-select-drop.up {
    background-position: 0 -1740px
}

.svgicon-fs-main {
    height: 22px;
    background-position: 0 -1790px
}

.svgicon-fs-sort {
    background-position: -40px -1790px
}

.svgicon-fs-sort.desc {
    background-position: -20px -1790px
}

.svgicon-fs-back {
    background-position: -60px -1790px
}

.svgicon-fs-trash {
    background-position: -80px -1790px
}

.svgicon-fs-card-protector {
    background-position: -100px -1790px
}

.svgicon-fs-card-protector.protected {
    background-position: -120px -1790px
}

.svgicon-fs-card-download {
    background-position: -140px -1790px
}

.svgicon-fs-card-settings {
    background-position: -160px -1790px
}

.svgicon-fs-card-play {
    width: 14px;
    height: 16px;
    background-position: -295px -1790px
}

.svgicon-fs-card-video {
    width: 46px;
    height: 24px;
    background-position: -440px -1790px
}

.svgicon-fs-card-audio {
    width: 25px;
    height: 31px;
    background-position: -640px -1790px
}

.svgicon-fs-card-doc {
    width: 24px;
    height: 27px;
    background-position: -670px -1790px
}

.svgicon-fs-card-file {
    width: 24px;
    height: 31px;
    background-position: -695px -1790px
}

.svgicon-fs-card-more {
    width: 31px;
    height: 7px;
    background-position: -585px -1815px
}

.svgicon-fs-card-preview {
    width: 20px;
    height: 20px;
    background-position: -585px -1790px
}

.svgicon-fs-card-preview_white {
    width: 22px;
    height: 20px;
    background-position: -650px -1850px
}

.svgicon-fs-list-filesgroup {
    background-position: -180px -1790px
}

.svgicon-fs-list-download {
    background-position: -200px -1790px
}

.svgicon-fs-list-view {
    background-position: -220px -1790px
}

.svgicon-fs-list-play {
    background-position: -240px -1790px
}

.svgicon-fs-list-settings {
    background-position: -260px -1790px
}

.link:hover > .svgicon-fs-list-settings, .svgicon-fs-list-settings.dark, a:hover > .svgicon-fs-list-settings {
    background-position: -260px -1810px
}

.svgicon-fs-list-protector {
    width: 13px;
    height: 13px;
    background-position: -280px -1790px;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
    margin-right: 3px
}

.svgicon-fs-player-play {
    width: 12px;
    height: 13px;
    background-position: -310px -1790px
}

.audioplayer.playing .svgicon-fs-player-play, .videoplayer.playing .svgicon-fs-player-play {
    width: 8px;
    background-position: -325px -1790px
}

.svgicon-fs-filetype-file {
    background-position: -340px -1790px
}

.svgicon-fs-filetype-doc {
    background-position: -360px -1790px
}

.svgicon-fs-filetype-audio {
    background-position: -380px -1790px
}

.svgicon-fs-filetype-video {
    background-position: -400px -1790px
}

.svgicon-fs-filetype-video.default {
    background-position: -420px -1790px
}

.svgicon-fs-preview-play {
    width: 32px;
    height: 36px;
    background-position: -550px -1790px
}

.svgicon-fs-preview-prev {
    width: 13px;
    height: 22px;
    background-position: -610px -1790px
}

.svgicon-fs-preview-next {
    width: 13px;
    height: 22px;
    background-position: -625px -1790px
}

.svgicon-fs-button-protector {
    width: 15px;
    height: 16px;
    background-position: -510px -1790px
}

.svgicon-fs-button-protector.protected {
    background-position: -530px -1790px
}

.svgicon-fs-button-chain {
    width: 13px;
    height: 13px;
    background-position: -490px -1790px
}

.svgicon-fs-gotocourse {
    width: 20px;
    height: 20px;
    background-position: -490px -1810px
}

.svgicon-fs-attach {
    background-position: -510px -1810px
}

.svgicon-add {
    background-position: -230px -2000px
}

.svgicon-hw-attach {
    width: 22px;
    height: 24px;
    background-position: -370px -1850px
}

.atpopover-trigger:hover .svgicon-hw-attach {
    background-position: -395px -1850px
}

.svgicon-hw-flash {
    width: 21px;
    height: 21px;
    background-position: -320px -1850px
}

.svgicon-hw-flash.active, :hover > .svgicon-hw-flash {
    background-position: -345px -1850px
}

.svgicon-hw-smile {
    width: 21px;
    height: 21px;
    background-position: -680px -1850px
}

.svgicon-hw-smile.active, :hover > .svgicon-hw-smile {
    background-position: -710px -1850px
}

.svgicon-hw-audio {
    width: 30px;
    height: 30px;
    background-position: 0 -1850px
}

:hover > .svgicon-hw-audio {
    background-position: -30px -1850px
}

.disabled:hover > .svgicon-hw-audio {
    background-position: 0 -1850px
}

.svgicon-hw-email {
    width: 25px;
    height: 21px;
    background-position: -182px -1850px
}

.svgicon-hw-email.active {
    background-position: -212px -1850px
}

@media (max-width: 767px) {
    .svgicon-hw-email {
        width: 36px;
        height: 30px;
        background-position: -420px -1850px
    }

    .svgicon-hw-email.active {
        background-position: -460px -1850px
    }
}

.svgicon-hw-sms {
    width: 37px;
    height: 21px;
    background-position: -240px -1850px
}

.svgicon-hw-sms.active {
    background-position: -280px -1850px
}

@media (max-width: 767px) {
    .svgicon-hw-sms {
        width: 46px;
        height: 30px;
        background-position: -500px -1850px
    }

    .svgicon-hw-sms.active {
        background-position: -550px -1850px
    }
}

.svgicon-hw-reward {
    width: 30px;
    height: 30px;
    background-position: -60px -1850px
}

input:checked + label .svgicon-hw-reward {
    background-position: -90px -1850px
}

.svgicon-hw-hold {
    width: 30px;
    height: 30px;
    background-position: -120px -1850px
}

input:checked + label .svgicon-hw-hold {
    background-position: -150px -1850px
}

.svgicon-hw-send {
    width: 30px;
    height: 30px;
    background-position: -600px -1850px
}

.svgicon-nav-courses {
    background-position: 0 -1910px
}

.svgicon-nav-homeworks {
    background-position: -20px -1910px
}

.svgicon-nav-diary {
    background-position: -40px -1910px
}

.new > .svgicon-nav-diary {
    background-position: -140px -1910px
}

.svgicon-nav-lessons {
    background-position: -60px -1910px
}

.svgicon-nav-students {
    background-position: -80px -1910px
}

.svgicon-nav-curators {
    background-position: -100px -1910px
}

.svgicon-nav-analisis {
    background-position: -120px -1910px
}

.svgicon-nav-messages {
    background-position: -160px -1910px
}

.svgicon-nav-emails {
    background-position: -180px -1910px
}

.svgicon-nav-faq {
    background-position: -200px -1910px
}

.svgicon-nav-rooms {
    background-position: -220px -1910px
}

.svgicon-nav-settings {
    background-position: -240px -1910px
}

.svgicon-nav-rights {
    background-position: -260px -1910px
}

.svgicon-nav-auto {
    background-position: -280px -1910px
}

.svgicon-nav-game {
    background-position: -300px -1910px
}

.svgicon-nav-ads {
    background-position: -320px -1910px
}

.svgicon-nav-author {
    background-position: -340px -1910px
}

.svgicon-nav-support {
    background-position: -360px -1910px
}

.svgicon-nav-partnership {
    background-position: -380px -1910px
}

.svgicon-nav-achievements {
    background-position: -400px -1910px
}

.svgicon-nav-performance {
    background-position: -420px -1910px
}

.svgicon-datepicker {
    background-position: -30px -2030px
}

.svgicon-diary {
    height: 23px;
    background-position: 0 -1970px
}

.diaryOn .day .svgicon-diary {
    background-position: -25px -1970px
}

.diaryOn .week .svgicon-diary {
    background-position: -50px -1970px
}

.diaryOn .month .svgicon-diary {
    background-position: -75px -1970px
}

.svgicon-edit {
    background-position: -50px -2030px
}

.svgicon-edit_framed {
    background-position: -70px -2030px
}

.svgicon-edit_framed.white {
    background-position: -130px -2030px
}

.svgicon-copy {
    background-position: -90px -2030px
}

.svgicon-remove {
    background-position: -110px -2030px
}

.svgicon-preview {
    background-position: -145px -2055px
}

.svgicon-search {
    background-position: -150px -2030px
}

.svgicon-search-clear {
    background-position: -170px -2030px
}

.svgicon-search-clear.active, :hover > .svgicon-search-clear {
    background-position: -170px -2050px
}

.svgicon-onAir {
    width: 20px;
    height: 20px;
    background-position: -630px -1850px;
    position: relative
}

.svgicon-onAir::before {
    display: block;
    content: "";
    width: 6px;
    height: 6px;
    background: #f95858;
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: 50%;
    animation: pulse 1s ease-in-out infinite
}

.svgicon-viewer-next, .svgicon-viewer-prev {
    width: 23px;
    height: 36px;
    opacity: .6
}

.link:hover .svgicon-viewer-next, .link:hover .svgicon-viewer-prev, a:hover .svgicon-viewer-next, a:hover .svgicon-viewer-prev, button:hover .svgicon-viewer-next, button:hover .svgicon-viewer-prev {
    opacity: 1
}

.svgicon-viewer-prev {
    background-position: -720px -1790px
}

.svgicon-viewer-next {
    background-position: -745px -1790px
}

.svgicon-viewer-fullscreen {
    background-position: -530px -1810px;
    opacity: .6
}

.link:hover .svgicon-viewer-fullscreen, a:hover .svgicon-viewer-fullscreen, button:hover .svgicon-viewer-fullscreen {
    opacity: .8
}

:-moz-full-screen .svgicon-viewer-fullscreen, :-ms-fullscreen .svgicon-viewer-fullscreen, :fullscreen .svgicon-viewer-fullscreen {
    background-position: -780px -1790px
}

.svgicon-notification.success {
    background-position: -305px -2050px
}

.svgicon-notification.info {
    background-position: -330px -2050px
}

.svgicon-notification.warning {
    background-position: -355px -2050px
}

.svgicon-notification.error {
    background-position: -380px -2050px
}

.svgicon-close {
    background-position: -170px -2030px
}

.svgicon-close.success {
    background-position: -480px -1980px
}

.svgicon-close.info {
    background-position: -480px -2000px
}

.svgicon-close.warning {
    background-position: -480px -2020px
}

.svgicon-close.error {
    background-position: -480px -2040px
}

.svgicon.inNotice {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 21px;
    height: 21px
}

.svgicon-dialog-close {
    background-position: 0 -2190px
}

.svgicon-tag-delete {
    width: 10px;
    height: 10px;
    background-position: 0 -2050px
}

.svgicon-collapse {
    width: 10px;
    height: 10px;
    background-position: -10px -2050px;
    transition: transform .3s
}

.svgicon-collapse.active {
    transform-origin: 50% 50%;
    transform: rotate(-180deg)
}

.svgpreloader {
    display: flex;
    align-items: center;
    justify-content: center
}

.svgpreloader::before {
    display: block;
    content: "";
    width: 32px;
    height: 32px;
    background: url('./svgsprite.svg') -270px -2010px;
    transform-origin: 50% 50%;
    animation: rotate 1s steps(12, end) infinite
}

.svgpreloader-white::before {
    background-position: -310px -2010px
}

.svgpreloader-16::before {
    width: 16px;
    height: 16px;
    background-position: -230px -2030px
}

.svgpreloader-16.svgpreloader-white::before {
    background-position: -250px -2030px
}

.svgpreloader-64::before {
    width: 64px;
    height: 64px;
    background-position: -350px -1980px
}

.svgpreloader-64.svgpreloader-white::before {
    background-position: -420px -1980px
}

@-o-keyframes rotate {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

@-moz-keyframes rotate {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

@-o-keyframes pulse {
    0%, 100% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.5)
    }
}

@-moz-keyframes pulse {
    0%, 100% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.5)
    }
}

@-webkit-keyframes pulse {
    0%, 100% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.5)
    }
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.5)
    }
}

.svgicon-video {
    background-position: 0 -2440px;
}

.svgicon-presentation {
    background-position: -20px -2440px;
}

.svgicon-presentation-video {
    background-position: -40px -2440px;
}

.svgicon-on-air {
    width: 26px;
    height: 26px;
    background-position: -70px -2440px;
}

.svgicon-on-air-draft {
    width: 26px;
    height: 26px;
    background-position: -100px -2440px;
}

.svgicon-bin {
    background-position: -130px -2440px;
}

.svgicon-bin.red {
    background-position: -150px -2440px;
}

.svgicon-monitor {
    width: 110px;
    height: 88px;
    background-position: -170px -2440px;
}

.svgicon-webinar-expand {
    width: 12px;
    height: 7px;
    background-position: -285px -2440px;
}

.svgicon-webinar-expand.active {
    width: 12px;
    height: 7px;
    background-position: -285px -2450px;
}

.svgicon-webinar-bell {
    width: 26px;
    height: 26px;
    background-position: -300px -2440px;
}

.svgicon-sw-students {
    background-position: -330px -2440px;
}

.svgicon-sw-camera {
    background-position: -350px -2440px;
}

.svgicon-sw-settings {
    background-position: -370px -2440px;
}

.svgicon-sw-entrance {
    background-position: -390px -2440px;
}

.svgicon-sw-ad {
    background-position: -410px -2440px;
}

.svgicon-sw-record {
    background-position: -430px -2440px;
}

.svgicon-sw-moderator {
    background-position: -450px -2440px;
}

.svgicon-sw-statistics {
    background-position: -470px -2440px;
}

.svgicon-sw-user {
    background-position: -490px -2440px;
}

.svgicon-sw-link {
    background-position: -510px -2440px;
}

.svgicon-sw-link-dark {
    background-position: -530px -2440px;
}

.svgicon-sw-link-gray {
    background-position: -550px -2440px;
}

.svgicon-sw-link-gray-dark {
    background-position: -570px -2440px;
}

.svgicon-sw-user-actions {
    background-position: -590px -2440px;
}

.svgicon-sw-download {
    background-position: 0 -2470px;
}

.svgicon-sw-cast {
    background-position: -20px -2470px;
}

.svgicon-sw-solid-bin {
    background-position: -40px -2470px;
}

.svgicon-sw-edit {
    background-position: -60px -2470px;
}

.svgicon-sw-back {
    background-position: -80px -2470px;
}

.svgicon-sw-layout-small-video-left-top {
    width: 30px;
    height: 30px;
    background-position: -100px -2470px;
}

.svgicon-sw-layout-small-video-right-bottom {
    width: 30px;
    height: 30px;
    background-position: -130px -2470px;
}

.svgicon-sw-layout-small-video {
    width: 30px;
    height: 28px;
    background-position: -100px -2500px;
}

.svgicon-sw-layout-small-presentation {
    width: 30px;
    height: 28px;
    background-position: -130px -2500px;
}

.svgicon-sw-courses {
    width: 26px;
    height: 26px;
    background-position: 0 -2490px;
}

.svgicon-sw-webinars {
    width: 26px;
    height: 26px;
    background-position: -30px -2490px;
}

.svgicon-sw-payments {
    width: 26px;
    height: 26px;
    background-position: -60px -2490px;
}

.svgicon-sw-close-modal {
    background-position: 0 -2520px;
}

.svgicon-sw-play-big {
    width: 80px;
    height: 80px;
    background-position: -285px -2470px
}

.svgicon-sw-play-big.active, :hover > .svgicon-sw-play-big {
    width: 80px;
    height: 80px;
    background-position: -370px -2470px
}

.svgicon-sw-play-small {
    width: 68px;
    height: 68px;
    background-position: -455px -2470px
}

.svgicon-sw-play-small.active, :hover > .svgicon-sw-play-small {
    width: 68px;
    height: 68px;
    background-position: -530px -2470px
}

.svgicon-sw-layout-video {
    width: 60px;
    height: 60px;
    background-position: -610px -2440px
}

.svgicon-sw-layout-presentation {
    width: 60px;
    height: 60px;
    background-position: -670px -2440px
}

.svgicon-sw-layout-presentation-video {
    width: 60px;
    height: 60px;
    background-position: -730px -2440px
}

.svgicon-sw-presentation {
    width: 30px;
    height: 30px;
    background-position: -610px -2500px
}

.svgicon-sw-presentation.white {
    width: 30px;
    height: 30px;
    background-position: -640px -2500px
}