@import '../../Theme';

.flag {
  flex-shrink: 0;
  width: 32px;
  height: 24px;
  margin: 8px;
  margin-left: 0;
  border: 1px solid #000000;
  background: url('./flags.svg') no-repeat;

  @include mediaTo(sm) {
    margin: 8px 0;
  }

  &Wrap {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

:global {
  .phoneInput-flag {
    &:hover {
      background: inherit;
    }

    & * {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      opacity: 0.3;

      &::before {
        content: '?';
      }
    }

    &-ad {
      background-position: 0 0;
    }

    &-ae {
      background-position: 0 -32px;
    }

    &-af {
      background-position: 0 -64px;
    }

    &-ag {
      background-position: 0 -96px;
    }

    &-ai {
      background-position: 0 -128px;
    }

    &-al {
      background-position: 0 -160px;
    }

    &-am {
      background-position: 0 -192px;
    }

    &-ao {
      background-position: 0 -224px;
    }

    &-aq {
      background-position: 0 -256px;
    }

    &-ar {
      background-position: 0 -288px;
    }

    &-as {
      background-position: 0 -320px;
    }

    &-at {
      background-position: 0 -352px;
    }

    &-au {
      background-position: 0 -384px;
    }

    &-aw {
      background-position: 0 -416px;
    }

    &-ax {
      background-position: 0 -448px;
    }

    &-az {
      background-position: 0 -480px;
    }

    &-ba {
      background-position: 0 -512px;
    }

    &-bb {
      background-position: 0 -544px;
    }

    &-bd {
      background-position: 0 -576px;
    }

    &-be {
      background-position: 0 -608px;
    }

    &-bf {
      background-position: 0 -640px;
    }

    &-bg {
      background-position: 0 -672px;
    }

    &-bh {
      background-position: 0 -704px;
    }

    &-bi {
      background-position: 0 -736px;
    }

    &-bj {
      background-position: 0 -768px;
    }

    &-bl {
      background-position: 0 -800px;
    }

    &-bm {
      background-position: 0 -832px;
    }

    &-bn {
      background-position: 0 -864px;
    }

    &-bo {
      background-position: 0 -896px;
    }

    &-bq {
      background-position: 0 -928px;
    }

    &-br {
      background-position: 0 -960px;
    }

    &-bs {
      background-position: 0 -992px;
    }

    &-bt {
      background-position: 0 -1024px;
    }

    &-bv {
      background-position: 0 -1056px;
    }

    &-bw {
      background-position: 0 -1088px;
    }

    &-by {
      background-position: 0 -1120px;
    }

    &-bz {
      background-position: 0 -1152px;
    }

    &-ca {
      background-position: 0 -1184px;
    }

    &-cc {
      background-position: 0 -1216px;
    }

    &-cd {
      background-position: 0 -1248px;
    }

    &-cf {
      background-position: 0 -1280px;
    }

    &-cg {
      background-position: 0 -1312px;
    }

    &-ch {
      background-position: 0 -1344px;
    }

    &-ci {
      background-position: 0 -1376px;
    }

    &-ck {
      background-position: 0 -1408px;
    }

    &-cl {
      background-position: 0 -1440px;
    }

    &-cm {
      background-position: 0 -1472px;
    }

    &-cn {
      background-position: 0 -1504px;
    }

    &-co {
      background-position: 0 -1536px;
    }

    &-cr {
      background-position: 0 -1568px;
    }

    &-cu {
      background-position: 0 -1600px;
    }

    &-cv {
      background-position: 0 -1632px;
    }

    &-cw {
      background-position: 0 -1664px;
    }

    &-cx {
      background-position: 0 -1696px;
    }

    &-cy {
      background-position: 0 -1728px;
    }

    &-cz {
      background-position: 0 -1760px;
    }

    &-de {
      background-position: 0 -1792px;
    }

    &-dj {
      background-position: 0 -1824px;
    }

    &-dk {
      background-position: 0 -1856px;
    }

    &-dm {
      background-position: 0 -1888px;
    }

    &-dz {
      background-position: 0 -1920px;
    }

    &-ec {
      background-position: 0 -1952px;
    }

    &-ee {
      background-position: 0 -1984px;
    }

    &-eg {
      background-position: 0 -2016px;
    }

    &-eh {
      background-position: 0 -2048px;
    }

    &-er {
      background-position: 0 -2080px;
    }

    &-es-ca {
      background-position: 0 -2112px;
    }

    &-es {
      background-position: 0 -2144px;
    }

    &-et {
      background-position: 0 -2176px;
    }

    &-eu {
      background-position: 0 -2208px;
    }

    &-fi {
      background-position: 0 -2240px;
    }

    &-fj {
      background-position: 0 -2272px;
    }

    &-fk {
      background-position: 0 -2304px;
    }

    &-fm {
      background-position: 0 -2336px;
    }

    &-fo {
      background-position: 0 -2368px;
    }

    &-fr {
      background-position: 0 -2400px;
    }

    &-ga {
      background-position: 0 -2432px;
    }

    &-gb-eng {
      background-position: 0 -2464px;
    }

    &-gb-nir {
      background-position: 0 -2496px;
    }

    &-gb-sct {
      background-position: 0 -2528px;
    }

    &-gb-wls {
      background-position: 0 -2560px;
    }

    &-gb {
      background-position: 0 -2592px;
    }

    &-gd {
      background-position: 0 -2624px;
    }

    &-ge {
      background-position: 0 -2656px;
    }

    &-gf {
      background-position: 0 -2688px;
    }

    &-gg {
      background-position: 0 -2720px;
    }

    &-gh {
      background-position: 0 -2752px;
    }

    &-gi {
      background-position: 0 -2784px;
    }

    &-gl {
      background-position: 0 -2816px;
    }

    &-gm {
      background-position: 0 -2848px;
    }

    &-gn {
      background-position: 0 -2880px;
    }

    &-gp {
      background-position: 0 -2912px;
    }

    &-gq {
      background-position: 0 -2944px;
    }

    &-gr {
      background-position: 0 -2976px;
    }

    &-gs {
      background-position: 0 -3008px;
    }

    &-gt {
      background-position: 0 -3040px;
    }

    &-gu {
      background-position: 0 -3072px;
    }

    &-gw {
      background-position: 0 -3104px;
    }

    &-gy {
      background-position: 0 -3136px;
    }

    &-hk {
      background-position: 0 -3168px;
    }

    &-hm {
      background-position: 0 -3200px;
    }

    &-hn {
      background-position: 0 -3232px;
    }

    &-hr {
      background-position: 0 -3264px;
    }

    &-ht {
      background-position: 0 -3296px;
    }

    &-hu {
      background-position: 0 -3328px;
    }

    &-id {
      background-position: 0 -3360px;
    }

    &-ie {
      background-position: 0 -3392px;
    }

    &-il {
      background-position: 0 -3424px;
    }

    &-im {
      background-position: 0 -3456px;
    }

    &-in {
      background-position: 0 -3488px;
    }

    &-io {
      background-position: 0 -3520px;
    }

    &-iq {
      background-position: 0 -3552px;
    }

    &-ir {
      background-position: 0 -3584px;
    }

    &-is {
      background-position: 0 -3616px;
    }

    &-it {
      background-position: 0 -3648px;
    }

    &-je {
      background-position: 0 -3680px;
    }

    &-jm {
      background-position: 0 -3712px;
    }

    &-jo {
      background-position: 0 -3744px;
    }

    &-jp {
      background-position: 0 -3776px;
    }

    &-ke {
      background-position: 0 -3808px;
    }

    &-kg {
      background-position: 0 -3840px;
    }

    &-kh {
      background-position: 0 -3872px;
    }

    &-ki {
      background-position: 0 -3904px;
    }

    &-km {
      background-position: 0 -3936px;
    }

    &-kn {
      background-position: 0 -3968px;
    }

    &-kp {
      background-position: 0 -4000px;
    }

    &-kr {
      background-position: 0 -4032px;
    }

    &-kw {
      background-position: 0 -4064px;
    }

    &-ky {
      background-position: 0 -4096px;
    }

    &-kz {
      background-position: 0 -4128px;
    }

    &-la {
      background-position: 0 -4160px;
    }

    &-lb {
      background-position: 0 -4192px;
    }

    &-lc {
      background-position: 0 -4224px;
    }

    &-li {
      background-position: 0 -4256px;
    }

    &-lk {
      background-position: 0 -4288px;
    }

    &-lr {
      background-position: 0 -4320px;
    }

    &-ls {
      background-position: 0 -4352px;
    }

    &-lt {
      background-position: 0 -4384px;
    }

    &-lu {
      background-position: 0 -4416px;
    }

    &-lv {
      background-position: 0 -4448px;
    }

    &-ly {
      background-position: 0 -4480px;
    }

    &-ma {
      background-position: 0 -4512px;
    }

    &-mc {
      background-position: 0 -4544px;
    }

    &-md {
      background-position: 0 -4576px;
    }

    &-me {
      background-position: 0 -4608px;
    }

    &-mf {
      background-position: 0 -4640px;
    }

    &-mg {
      background-position: 0 -4672px;
    }

    &-mh {
      background-position: 0 -4704px;
    }

    &-mk {
      background-position: 0 -4736px;
    }

    &-ml {
      background-position: 0 -4768px;
    }

    &-mm {
      background-position: 0 -4800px;
    }

    &-mn {
      background-position: 0 -4832px;
    }

    &-mo {
      background-position: 0 -4864px;
    }

    &-mp {
      background-position: 0 -4896px;
    }

    &-mq {
      background-position: 0 -4928px;
    }

    &-mr {
      background-position: 0 -4960px;
    }

    &-ms {
      background-position: 0 -4992px;
    }

    &-mt {
      background-position: 0 -5024px;
    }

    &-mu {
      background-position: 0 -5056px;
    }

    &-mv {
      background-position: 0 -5088px;
    }

    &-mw {
      background-position: 0 -5120px;
    }

    &-mx {
      background-position: 0 -5152px;
    }

    &-my {
      background-position: 0 -5184px;
    }

    &-mz {
      background-position: 0 -5216px;
    }

    &-na {
      background-position: 0 -5248px;
    }

    &-nc {
      background-position: 0 -5280px;
    }

    &-ne {
      background-position: 0 -5312px;
    }

    &-nf {
      background-position: 0 -5344px;
    }

    &-ng {
      background-position: 0 -5376px;
    }

    &-ni {
      background-position: 0 -5408px;
    }

    &-nl {
      background-position: 0 -5440px;
    }

    &-no {
      background-position: 0 -5472px;
    }

    &-np {
      background-position: 0 -5504px;
    }

    &-nr {
      background-position: 0 -5536px;
    }

    &-nu {
      background-position: 0 -5568px;
    }

    &-nz {
      background-position: 0 -5600px;
    }

    &-om {
      background-position: 0 -5632px;
    }

    &-pa {
      background-position: 0 -5664px;
    }

    &-pe {
      background-position: 0 -5696px;
    }

    &-pf {
      background-position: 0 -5728px;
    }

    &-pg {
      background-position: 0 -5760px;
    }

    &-ph {
      background-position: 0 -5792px;
    }

    &-pk {
      background-position: 0 -5824px;
    }

    &-pl {
      background-position: 0 -5856px;
    }

    &-pm {
      background-position: 0 -5888px;
    }

    &-pn {
      background-position: 0 -5920px;
    }

    &-pr {
      background-position: 0 -5952px;
    }

    &-ps {
      background-position: 0 -5984px;
    }

    &-pt {
      background-position: 0 -6016px;
    }

    &-pw {
      background-position: 0 -6048px;
    }

    &-py {
      background-position: 0 -6080px;
    }

    &-qa {
      background-position: 0 -6112px;
    }

    &-re {
      background-position: 0 -6144px;
    }

    &-ro {
      background-position: 0 -6176px;
    }

    &-rs {
      background-position: 0 -6208px;
    }

    &-ru {
      background-position: 0 -6240px;
    }

    &-rw {
      background-position: 0 -6272px;
    }

    &-sa {
      background-position: 0 -6304px;
    }

    &-sb {
      background-position: 0 -6336px;
    }

    &-sc {
      background-position: 0 -6368px;
    }

    &-sd {
      background-position: 0 -6400px;
    }

    &-se {
      background-position: 0 -6432px;
    }

    &-sg {
      background-position: 0 -6464px;
    }

    &-sh {
      background-position: 0 -6496px;
    }

    &-si {
      background-position: 0 -6528px;
    }

    &-sj {
      background-position: 0 -6560px;
    }

    &-sk {
      background-position: 0 -6592px;
    }

    &-sl {
      background-position: 0 -6624px;
    }

    &-sm {
      background-position: 0 -6656px;
    }

    &-sn {
      background-position: 0 -6688px;
    }

    &-so {
      background-position: 0 -6720px;
    }

    &-sr {
      background-position: 0 -6752px;
    }

    &-ss {
      background-position: 0 -6784px;
    }

    &-st {
      background-position: 0 -6816px;
    }

    &-sv {
      background-position: 0 -6848px;
    }

    &-sx {
      background-position: 0 -6880px;
    }

    &-sy {
      background-position: 0 -6912px;
    }

    &-sz {
      background-position: 0 -6944px;
    }

    &-tc {
      background-position: 0 -6976px;
    }

    &-td {
      background-position: 0 -7008px;
    }

    &-tf {
      background-position: 0 -7040px;
    }

    &-tg {
      background-position: 0 -7072px;
    }

    &-th {
      background-position: 0 -7104px;
    }

    &-tj {
      background-position: 0 -7136px;
    }

    &-tk {
      background-position: 0 -7168px;
    }

    &-tl {
      background-position: 0 -7200px;
    }

    &-tm {
      background-position: 0 -7232px;
    }

    &-tn {
      background-position: 0 -7264px;
    }

    &-to {
      background-position: 0 -7296px;
    }

    &-tr {
      background-position: 0 -7328px;
    }

    &-tt {
      background-position: 0 -7360px;
    }

    &-tv {
      background-position: 0 -7392px;
    }

    &-tw {
      background-position: 0 -7424px;
    }

    &-tz {
      background-position: 0 -7456px;
    }

    &-ua {
      background-position: 0 -7488px;
    }

    &-ug {
      background-position: 0 -7520px;
    }

    &-um {
      background-position: 0 -7552px;
    }

    &-un {
      background-position: 0 -7584px;
    }

    &-us {
      background-position: 0 -7616px;
    }

    &-uy {
      background-position: 0 -7648px;
    }

    &-uz {
      background-position: 0 -7680px;
    }

    &-va {
      background-position: 0 -7712px;
    }

    &-vc {
      background-position: 0 -7744px;
    }

    &-ve {
      background-position: 0 -7776px;
    }

    &-vg {
      background-position: 0 -7808px;
    }

    &-vi {
      background-position: 0 -7840px;
    }

    &-vn {
      background-position: 0 -7872px;
    }

    &-vu {
      background-position: 0 -7904px;
    }

    &-wf {
      background-position: 0 -7936px;
    }

    &-ws {
      background-position: 0 -7968px;
    }

    &-xk {
      background-position: 0 -8000px;
    }

    &-ye {
      background-position: 0 -8032px;
    }

    &-yt {
      background-position: 0 -8064px;
    }

    &-za {
      background-position: 0 -8096px;
    }

    &-zm {
      background-position: 0 -8128px;
    }

    &-zw {
      background-position: 0 -8160px;
    }
  }
}
