.List {
  padding: 0;
  margin: 0 14px;
  list-style: none;
}

.ListItem {
  flex: 1 1 auto;
  display: flex;
  border-bottom: 1px solid #dfe2e8;

  &.hidden {
    display: none;
  }

  + span {
    flex: 9999999999999999;
  }
}

.ListItem:last-child {
  border-bottom: none;
}

.cutter {
  flex: 0 0 auto;
  border-bottom: none;
}

.ListItemLessonTitle {
  font-size: 19px;
}

.noBorder {
  padding-bottom: 14px;
  border-bottom: none;
}

.noMargin {
  margin: 0;
}

.danger {
  background-color: #fceceb;
}

.success {
  background-color: #f1f8eb;
}

.fullPadding {
  padding: 13px;
}

.verticalPadding {
  padding: 13px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
