/*
 * Дублирование переменных темы Material UI
 */

// Breakpoints
$_xs: 0;
$_sm: 768px;
$_md: 1000px;
$_lg: 1280px;
$_xl: 1600px;

// Colors
$common-black: #000000;
$common-white: #ffffff;
$common-border: #e3e4e8;
$common-red: #e35248;
$common-blue: #0aa4ff;

$primary-dark: #2C3A98;
$primary-light: #8B97E8;
$primary-lighter: #929eb0;
$primary-main: #3F53D9;

$grey-0: #1d1d1d;
$grey-50: #f9fafe;
$grey-100: #f0f2f5;
$grey-200: #eff1f4;
$grey-400: #c0c7d1;
$grey-500: #b6b6b6;
$grey-600: #6d6d6d;
$grey-A100: #f9fafe;
$grey-A200: #f5f6fd;
$grey-A700: #eceefb;

$fader: rgba(0, 0, 0, 0.8);
$controls: rgba(77, 78, 79, 0.75);

$shop-card-hover: #3F53D980;

$lesson-theory: #e4f8ff;
$lesson-task: #fbf0da;
$lesson-test: #ebffe7;
$lesson-webinar: #fce7e5;

$badge-red-bg: #fceceb;
$badge-red-color: #e35248;
$badge-blue-bg: #e3f5ff;
$badge-blue-color: #0aa4ff;

$students-red-offline: #efaaaa;
$students-green-online: #a6d4b1;

$shop: #ff8c11;
$subscribe-green: #30ab59;

$lesson-task: #ffeedb;
$lesson-test: #ebffe7;
$lesson-theory: #e4f8ff;
$lesson-webinar: #fee4e5;

// Sizes
$spacing: 8px;
$bdrs: 2px;

// Mixins
// Breakpoints
$breakpoints: (
        "xs": $_xs,
        "sm": $_sm,
        "md": $_md,
        "lg": $_lg,
        "xl": $_xl,
);

@mixin mediaTo($w) {
  @media (max-width: #{map-get($breakpoints, $w) - 1}) {
    @content;
  }
}

@mixin mediaFrom($w) {
  @media (min-width: #{map-get($breakpoints, $w)}) {
    @content;
  }
}

// Hidden
@include mediaTo(sm) {
  .onlyXs {
    display: none !important;
  }
}

@include mediaFrom(sm) {
  .smUp {
    display: none !important;
  }
}

@include mediaTo(md) {
  .mdDown {
    display: none !important;
  }
}

// LineClamp
@mixin lineClamp($lines: 2, $lineHeight: 1.27) {
  display: block;
  display: -webkit-box;
  line-clamp: #{$lines};
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: #{$lines};
  -webkit-box-orient: vertical;
  max-height: 1em * $lines * $lineHeight;
  line-height: $lineHeight * 1em;
  word-break: break-word;
}
