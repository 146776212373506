.adornment {
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  background: none;
}

.start {
  border-right-width: 1px;
}

.end {
  border-left-width: 1px;
}
