.user {
  &Name {
    font-family: "SF UI Text";
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
  }
}

.hideLong {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
