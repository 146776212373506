.cSwitch {
  display: inline-block;
}

.cSwitchMargin {
  margin-top: 3px;
  vertical-align: middle;
}

.switcher {
  display: block;
  width: 28px;
  height: 14px;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease-out;
  background-color: #dcdcdc;
  margin: 0;
  padding: 0;
}

.switcher:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #99b2c6;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: -1px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.active {
  background-color: #a6d4b1;
}

.active:before {
  right: 0;
}

.note {
  display: block;
}
