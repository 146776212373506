@import "../Theme";

.container {
  position: relative;

  &.labeled {
    width: auto;
  }
}

.trigger {
  overflow: hidden;
  white-space: nowrap;

  &.md {
    width: 64px !important;
  }
}

.renew {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: auto;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  border-radius: 50%;
  transition: transform 0.2s;
}

.trigger:not(:hover) .renew:not(.active) {
  transform: translateY(35px);
}

.colorpicker {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%);

  &.bottom {
    top: 100%;
    bottom: auto;
  }

  @include mediaTo(sm) {
    left: 0;
    transform: none;
  }
}

.confirmButton {
  display: block;
}

:global(.sketch-picker) {
  padding: 0 !important;
  background: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  input {
    text-align: center;
  }
}
