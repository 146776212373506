@import "../Theme";

@include mediaTo(sm) {
  .Paper {
    border-radius: 0 !important;
  }

  .head {
    border-radius: 0 !important;
  }

  .Sticky {
    border-radius: 0 !important;
  }
}
