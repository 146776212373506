.cardTab {
  display: inline-block;
  padding-top: 17px;
  padding-bottom: 14px;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 14px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  margin-bottom: -1px;

  &:last-child {
    margin-right: 0 !important;
  }

  @media screen and (max-width: 767px) {
    margin-right: 8px;
    padding-right: 1px;
    padding-left: 1px;
    font-size: 13px;
  }

  :global(.block_title) & {
    padding: 20px 7px 18px 7px;
    font-size: 15px;
    font-weight: 600;
  }
}

.tabLink {
  text-decoration: none;
}

.active {
  :global(.block_title) & {
    padding-bottom: 17px;
  }
}
