@import "../_Theme";

.section {
  &:last-child {
    border-bottom: none !important;
  }

  &.withHead {
    border: none !important;
  }

  &Head {
    margin-top: -1px;
    padding: 18px 20px;
  }

  :global(.noheadMUIBlock) &:first-child &Head {
    margin-top: 0;
    border-top: none !important;
  }

  &Body {
    padding: 20px 20px 5px 20px;

    &.reversedPadding {
      padding-top: 5px;
      padding-bottom: 20px;
    }

    &.inner {
      margin-right: 20px;
      margin-left: 20px;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.row {
  max-width: 100%;
  margin-bottom: 15px;

  &.lowMargin {
    margin-bottom: 10px;
  }

  &.noMargin {
    margin-bottom: 0;
  }
  &.miniMargin {
    margin-bottom: 4px;
  }

  &Info {
    margin-right: -20px;
    margin-left: -20px;
  }

  &Info & {
    padding: 20px;

    img {
      display: block;
      max-width: 100%;
    }

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.divider {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 268px;
  min-height: 32px;
  margin-right: 10px;
  text-align: right;
  &Small,
  :global(.no1200step) &,
  :global(.central.left) & {
    width: 206px;
  }

  &Empty {
    min-height: 1px;
  }

  @include mediaTo(lg) {
    width: 168px;
    @include lineClamp(2);
    text-overflow: ellipsis;
  }

  @include mediaTo(sm) {
    display: block;
    width: 100% !important;
    min-height: 0;
    margin-right: 0;
    margin-bottom: 8px;
    text-align: left;
  }
}

.fields {
  display: flex;
  align-items: center;
  min-height: 32px;

  @include mediaTo(sm) {
    min-height: 40px;
  }
}

.editor {
  display: flex;
}

.caption {
  margin-top: 5px;
}

.hints {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 32px;
  margin: 0 10px;

  &Empty {
    min-height: 1px;
  }

  :global(.MuiButton-iconAction) {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  @include mediaTo(sm) {
    width: 40px;
    min-height: 40px;
    margin-right: 0;
  }
}
