.DropdownSelect {
  display: block;
  height: 32px;
  border: 1px solid #d1d9e0;
  background: #ffffff;
  text-decoration: none;
  border-radius: 2px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;

  &Current {
    display: block;
    height: 32px;
    padding-left: 8px;
    padding-right: 20px;
    color: #444444;
    line-height: 29px;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:after {
      display: block;
      content: " ";
      width: 10px;
      height: 6px;
      // background: url("/public/images/_anti/student/sprite.png") no-repeat -45px -60px;
      -webkit-background-size: 500px 500px;
      background-size: 500px 500px;
      margin-top: -3px;
      position: absolute;
      top: 50%;
      right: 7px;
    }

    &Open {
      z-index: 1000;

      &:after {
        background-position: -45px -70px;
      }
    }
  }

  &ItemList {
    display: block;
    max-height: 200px;
    border: 1px solid #d1d9e0;
    background: #ffffff;
    position: absolute;
    right: -1px;
    bottom: 100%;
    left: -1px;
    z-index: 1000;
    overflow: auto;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  &Down &ItemList {
    top: 100%;
    bottom: auto;
  }

  &Item {
    padding: 6px;
    cursor: pointer;

    &:hover {
      background: #6285af;
      color: #ffffff;
    }
  }

  &Blocker {
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
}
