.dtPicker {
  :global(.MuiIconButton-root) {
    padding: 4px;
    margin-bottom: -28px;
  }
}

.timePicker {
  :global(.MuiInputAdornment-root) {
    display: none;
  }

  input {
    padding: 6px;
  }
}
