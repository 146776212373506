.Dropdown {
  font-size: 13px;
  color: #2C3A98;
  user-select: none;
  position: relative;
}

:local(.DropdownCurrent):before {
  margin: 1px -19px 0 8px;
  float: right;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS4wMSA1Ljk3Ij48dGl0bGU+0KDQtdGB0YPRgNGBIDI8L3RpdGxlPjxnIGlkPSLQodC70L7QuV8yIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDIiPjxnIGlkPSLQodC70L7QuV8xLTIiIGRhdGEtbmFtZT0i0KHQu9C+0LkgMSI+PHBhdGggZD0iTTEwLjc1LjQ4YTEsMSwwLDAsMC0xLjI4LDBMNS42Niw0LDEuNTUuMjRBMSwxLDAsMCwwLC4yNy4yNGEuNzguNzgsMCwwLDAsMCwxLjE4bDQuNSw0LjEuMTUuMDlBLjU0LjU0LDAsMCwwLDUsNS43M2ExLDEsMCwwLDAsMS4yOCwwbDQuNDYtNC4wOEEuNzguNzgsMCwwLDAsMTAuNzUuNDhaIi8+PC9nPjwvZz48L3N2Zz4=);
  background-size: 11px 6px;
  width: 11px;
  height: 15px;
  background-position: 50% 50%;
  opacity: 0.4;
  background-repeat: no-repeat;
  content: "";
}

.DropdownCurrent {
  padding: 0 19px 0 0;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}

.DropdownItemList {
  position: absolute;
  transform: translateY(7px);
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2C3A98;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(180, 189, 197, 0.3);
  display: block;
  left: 0;
}

:local(.DropdownItemList):before {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  top: -5px;
  left: 24px;
  transform: rotate(-45deg);
  background-color: #fff;
}

.DropdownItemList > div {
  max-height: 200px;
  overflow: auto;
}

.DropdownItem {
  display: block;
  width: 100%;
  padding: 0.4rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #2C3A98;
  text-align: inherit;
  //white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}

:local(.DropdownItem):hover {
  background-color: #f0f2f5;
}

.DropdownClose {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
