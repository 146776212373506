.Input {
  width: 200px;
  height: 30px;
  color: #000;
  margin: 0;
  border: 1px solid #d3d9de;
  padding: 5px 9px 7px;
  border-radius: 2px;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
}

.FullWidth {
  flex: 1 1 100%;
  width: 100%;
}

.search {
  background-color: transparent;
  width: 100%;
  border: none;
  border-bottom: 2px solid transparent;
}

.search::placeholder {
  color: #b3c2da;
}

.search:focus {
  border-color: #b3c2da;
}
