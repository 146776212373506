@import "../Theme";

.closable {
  padding-right: 40px !important;

  &Trigger {
    padding: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    @media screen and (max-width: 640px) {
      top: 12px;
    }
    &.mobileonly {
      background: rgba(255, 255, 255, 0.2);
      top: 0;
      right: 0;
      z-index: 1;
    }

    :global(.achievementsDialog) & {
      position: fixed;
    }
  }
}

.centered {
  text-align: center;
}
