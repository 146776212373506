@import "../Theme";

.MuiLiveInput {
  &.active {
    padding: 0;
  }

  &Text {
    font-family: "SF UI Text", "Arial", "Helvetica", sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
}

.notActive {
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 10px;
  line-height: 30px;
  border-radius: 2px;

  &Text {
    @include lineClamp(2, 1.62);
  }

  &Icon {
    padding: 0 10px;
  }

  @include mediaFrom(sm) {
    &:not(:hover) {
      background: none !important;

      .notActiveIcon {
        display: none;
      }
    }
  }

  @include mediaTo(sm) {
    height: 40px;
    line-height: 40px;
  }
}

.input {
  border-radius: 2px !important;
  height: 30px !important;
  line-height: 30px !important;
  font-weight: 400 !important;

  & fieldset {
    border-width: 1px !important;
  }
}
