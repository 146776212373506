@import "../../../MUI/_Theme";

.tableInfo {
  position: relative;
  padding: 20px 40px 20px 20px;
  background-color: #ffffff;

  &Close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}
