.Button {
  display: inline-block;
  padding: 7px 14px;
  border: none;
  background: #3F53D9;
  color: #ffffff;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;

  &:global(.disp-block) {
    display: block;
    width: 100%;
    box-sizing: border-box;

    & + & {
      margin-top: 7px;
    }
  }

  &:hover {
    background: #6e8db0;
  }

  &.green {
    background: #82c04b;

    &:hover {
      background: #94ca65;
    }
  }

  &.red {
    background: #e35248;

    &:hover {
      background: #e66b61;
    }
  }

  &.light {
    background: #d1d9e0;
    color: #2C3A98 !important;

    &:hover {
      background: darken(#d1d9e0, 5%);
      color: #2C3A98 !important;
    }
  }

  &.lighter {
    background: #eff1f4;
    color: #2C3A98 !important;

    &:hover {
      background: #d1d9e0;
      color: #2C3A98 !important;
    }
  }
}
