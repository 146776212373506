.Star {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #c07e00;
  height: 30px;
  line-height: 20px;
}

.Star:after {
  display: inline-block;
  content: " ";
  width: 20px;
  height: 18px;
  margin-left: 3px;
  vertical-align: top;
  // background: url(/public/images/_anti/student/sprite.png) no-repeat 0 0;
  background-size: 500px 500px;
  background-position: -400px 0;
}
