@import '../Theme';

.tooltip {
  color: $primary-dark;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding-left: 1px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  cursor: help;
  box-sizing: border-box;
  border-radius: 50%;
}
