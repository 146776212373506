.cardTabs {
  font-size: 15px;
  margin-top: -14px;
  margin-bottom: 14px;

  :global(.block_title) & {
    margin: -17px -13px -19px -13px;
    padding: 0 13px;
    border: none;
  }
}

.cardTabsSwitcher {
  padding: 0;
}
