@import "../Theme";

.tabWrap {
  margin-bottom: -1px;
}

.tabButton {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;

  @include mediaFrom(sm) {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
  }
}
