@import "../Theme";

.sidebar {
  flex: 1;
  max-width: 240px;

  @include mediaTo(md) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include mediaTo(md) {
  .nav {
    display: flex;
    padding: 0 !important;
    overflow: auto;

    &Item {
      flex: 0 0 auto;
      padding-top: #{$spacing * 2} !important;
      padding-bottom: #{$spacing * 2} !important;

      &:global(.Mui-selected) {
        background: none !important;

        &::before {
          width: auto !important;
          height: 2px;
          top: auto !important;
          right: 0;
        }
      }
    }

    &Divider {
      display: none;
    }
  }
}

.navItemInner {
  padding-left: 32px !important;
}
