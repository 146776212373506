.wrap {
  display: flex;
  position: relative;

  &:hover {
    .deleteButton {
      display: flex;
    }
  }
}

.input {
  display: block;
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.button {
  min-width: 64px !important;
  min-height: 64px;
  position: relative;
  overflow: hidden;
}

.deleteButton {
  display: none;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;

  :global(.svgicon) {
    transform: scale(0.8);
  }
}

.cover {
  background: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="10" height="10" fill="lightgrey" /><rect x="10" y="10" width="10" height="10" fill="lightgrey" /><rect x="10" y="0" width="10" height="10" fill="white" /><rect x="0" y="10" width="10" height="10" fill="white" /></svg>') 0 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .image {
    display: block;
    width: 100%;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: opacity 0.2s;
  }

  .refresh {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: auto;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: -35px;
    left: 0;
    border-radius: 50%;
    transition: top 0.2s, bottom 0.2s;
  }

  &:hover {
    .image {
      opacity: 0.5;
    }

    .refresh {
      top: 0;
      bottom: 0;
    }
  }
}

.plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin: auto;
  border-radius: 50%;
}

.refresh,
.plus {
  position: relative;
  z-index: 2;
}

.crop {
  min-height: 400px;
  background: url('data:image/svg+xml;utf8,<svg preserveAspectRatio="none" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="10" height="10" fill="lightgrey" /><rect x="10" y="10" width="10" height="10" fill="lightgrey" /><rect x="10" y="0" width="10" height="10" fill="white" /><rect x="0" y="10" width="10" height="10" fill="white" /></svg>') 0 0;
  position: relative;

  &Rotate {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.coursesGroup {
  width: 96px;
  height: 60px;
  min-height: 60px;
}

.siteBG {
  .cover {
    margin: -5px;
    position: static;

    img {
      position: static;
    }
  }
}
