.Badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 24px;
  height: 24px;
  background-color: #dae1e8;
  border-radius: 50%;
  text-transform: uppercase;
}

.success {
  background-color: #f1f8eb;
  color: #82c04b;
}

.danger {
  background-color: #fceceb;
  color: #e35248;
}

.info {
  background-color: #e3f5ff;
  color: #0aa4ff;
}

.draft {
  background: #7d8895;
  color: #ffffff;
}

.typeRect {
  font-size: 13px;
  line-height: 16px;
  height: auto;
  width: auto;
  padding: 4px 10px;
  border-radius: 2px;
  min-width: 50px;
  min-height: 8px;
  font-weight: 400;
}

.large {
  padding: 8px;
}

.medium {
  padding: 4px;
}
