.file {
  display: inline-block;
  padding: 8px 14px 6px 14px;
  border: none;
  background: #3F53D9;
  color: #ffffff;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  font-weight: normal;
}
