@import "../../MUI/Theme";

.changeBox {
  position: relative;
  cursor: pointer;
  min-height: 30px;
  padding-bottom: 5px;
  &:hover {
    background-color: rgba(94, 128, 167, 0.07);
  }

  &Icon {
    position: absolute;
    color: $primary-light;
    right: 10px;
    top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::before {
      content: "Изменить";
      right: 0;
      color:rgba(94, 128, 167, 0.00);
      min-width: 20px;
    }
    &:hover {
      &::before {
        display: block;
        color: $primary-light;
        padding-left: 4px;
        background-color: rgba(242,244,248);
      }
    }
  }
    

  &Settings {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
  }

  &Row {
    display: flex;
    // max-height: 30px;
    align-items: center;
    padding-bottom: 12px;
  }

  &Buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.presentationTitle {
  @include lineClamp(2);
}

.chatMessage {
  @include lineClamp(3);
}
